import { App, Button, DatePicker, Input, InputNumber, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { api, apiSecure } from "../../components/api";
import moment from "moment";
import dayjs from 'dayjs';
import { ExclamationCircleFilled } from "@ant-design/icons";

export const EditWorkModal = ({
  editWorkData,
  isModalOpen,
  setIsModalOpen,
  projects,
  groups,
  refreshData=()=>{}
}) => {
  const [workerData, setWorkerData] = useState(null);
  const { modal } = App.useApp();

  const [startDate, setStartDate] = useState(null);
  const [project, setProject] = useState(null);
  const [hours, setHours] = useState(null);

  useEffect(() => {
    if (isModalOpen) {
      console.log("Modal opened", editWorkData);
      setWorkerData(groups.find((itm) => itm.id === editWorkData?.userid))
      setStartDate(dayjs(editWorkData?.start))
      setProject(editWorkData?.projectid);
      setHours(editWorkData?.hours);
    } else {
      setWorkerData(null)
      setStartDate(null);
      setProject(null);
      setHours(null);
    }
  }, [isModalOpen]);

  console.log('workerData',workerData)

  const handleCancel = () => {
    setIsModalOpen(false);
  };



  const showDeleteConfirm = (fileName, fileId) => {
    return new Promise((resolve, reject) => {
    modal.confirm({
      title: "Haluatko poistaa merkinnän?",
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: "Kyllä",
      okType: "danger",
      cancelText: "Ei",
      onOk() {
        resolve(true);
      },
      onCancel() {
        resolve(true);
      },
    });
  });

  };


  const editWork = () => {
    console.log("editWork");

    console.log("startDate", startDate);
    console.log("project", project);
    console.log("hours", hours);

    if (!startDate) return;
    if (!project) return;
    if (hours <= 0) return;
    console.log("läpi");

    let req_config = {
      method: "POST",
      url: `/editCalendarWork`,
      data: {
        workId: editWorkData?.id,
        startDate: startDate,
        project: project,
        hours: hours,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        refreshData();
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const deleteWork = async () => {
    console.log('deleteWork')

    let canDelete = await showDeleteConfirm()

    if (!canDelete) return
    let req_config = {
      method: "POST",
      url: `/removeCalendarWork`,
      data: {
        workId: editWorkData?.id
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        refreshData();
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  return (
    <>
      <Modal
        title="Muokkaa työtä"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'2em'
          }}
        >
          <p style={{ marginRight: 10 }}>Työntekijä:</p>
          <p>{workerData?.title}</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Aloitus pvm:</p>

          <DatePicker
            value={startDate}
            format={"DD.MM.YYYY"}
            placeholder="Valitse pvm"
            onChange={(date, dateString) => {
              console.log(date, dateString);
              setStartDate(date);
            }}
            status={!startDate ? "error" : ""}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Projekti:</p>

          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Valitse projekti"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            status={!project ? "error" : ""}
            value={project}
            onChange={(newValue) => {
              setProject(newValue);
            }}
            options={
              projects?.map((itm) => {
                return { value: itm.id, label: itm.name };
              })
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Tunnit:</p>

          <InputNumber
            style={{ width: 150 }}
            addonAfter={"Tuntia"}
            step={8}
            status={!hours || hours <= 0 ? "error" : ""}
            value={hours}
            onChange={(value) => {
              console.log("changed", value);
              setHours(value);
            }}
          />

          <div>
            <p style={{ marginLeft: "10px", fontWeight: "bold" }}>
              {" "}
              {hours > 0 ? " " + hours / 8 + " työpäivää" : ""}{" "}
            </p>
          </div>
        </div>
        <br />

        <div>

        <Button type="primary" onClick={editWork}>
          Tallenna
        </Button>

        <Button type="primary" onClick={deleteWork} style={{backgroundColor:'red', marginLeft:'3em'}}>
          Poista
        </Button>
        </div>

      </Modal>
    </>
  );
};
