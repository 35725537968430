import { App, Button, Checkbox, DatePicker, Input, InputNumber, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { api, apiSecure } from "../../components/api";

export const EditProjectModal = ({
  projectData,
  isModalOpen,
  setIsModalOpen,
  refreshData=()=>{}
}) => {
  const { message } = App.useApp();

  const [isLoading, setIsLoading] = useState(false);


  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [postAddress, setPostAddress] = useState('');
  const [schedule, setSchedule] = useState('');
  const [archived, setArchived] = useState(false);



  useEffect(() => {
    if (isModalOpen) {
      console.log("Modal opened", projectData);
      setName(projectData?.tiedot?.name);
      setAddress(projectData?.tiedot?.address);
      setPostAddress(projectData?.tiedot?.postaddress);
      setSchedule(projectData?.tiedot?.schedule);
      setArchived(projectData?.tiedot?.archived);
    } else {
      setName('');
      setAddress('');
      setPostAddress('');
      setSchedule('');
      setArchived('');
    }
  }, [isModalOpen]);


  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const updateContact = () => {
    setIsLoading(true)

    let req_config = {
      method: "POST",
      url: `/editProjectDataWeb`,
      data: {
        projectId: projectData?.tiedot?.id,
        name: name,
        address:address,
        postaddress: postAddress,
        schedule: schedule,
        archived: archived
      },
    };
    console.log('req', req_config)

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        refreshData();
        setIsModalOpen(false);
        message.success('Projektin tiedot päivitetty!');
        setIsLoading(false)

      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  return (
    <>
      <Modal
        title="Muokkaa projektin tietoja"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Nimi:</p>

          <Input
            style={{ width: 150 }}
            status={!name}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Osoite:</p>

          <Input
            style={{ width: 150 }}
            status={!address}
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Postiosoite:</p>

          <Input
            style={{ width: 150 }}
            status={!postAddress}
            value={postAddress}
            onChange={(e) => {
              setPostAddress(e.target.value);
            }}
          />
        </div>


        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Aikataulu:</p>

          <Input
            style={{ width: 150 }}
            status={!schedule}
            value={schedule}
            onChange={(e) => {
              setSchedule(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <Checkbox
            checked={archived}
            onChange={(e) => {
              console.log(`checked = ${e.target.checked}`);
              setArchived(e.target.checked)
            }}
          >
            Arkistoitu
          </Checkbox>
        </div>
        <br />
        <Button type="primary" onClick={updateContact} loading={isLoading}>
          Tallenna tiedot
        </Button>
      </Modal>
    </>
  );
};
