import { Outlet } from "react-router-dom";
import Header from "./Header";
import { App, ConfigProvider, Layout as LayoutAntd, theme } from "antd";

export default function Layout() {
  return (
    <>


<ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            borderRadius: 2,
            colorText:'#fff'
          },
          components: {
            Layout: {
              headerBg: '#1c1c1c',
              colorBorder:'red'
            },
            Menu: {
              itemBg:'#1c1c1c'
            }
          },
        }}
      >


<App>
      <LayoutAntd className="layout" style={{minHeight:'100vh'}}>
        <Header />

          <Outlet />

      </LayoutAntd>
      </App>

      </ConfigProvider>
    </>
  );
}
