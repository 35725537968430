import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import Projects from "../Projects/Projects";
import Layout from "../../components/layout";
import Schedule from "../Schedule/Schedule";
import Login from "../Login/Login";
import { useUserStore } from "../../state/state";
import Project from "../Project/Project";

import "../../index.css";
import { App } from "antd";

const PrivateRoutes = () => {
  const location = useLocation();

  const authLogin = useUserStore((state) => state.authenticated);
  console.log("PrivateRoutes", authLogin);

  return authLogin ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

const LoadingPage = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgb(40, 40, 40)",
      }}
    ></div>
  );
};

function RouterView() {
  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    if (!authInitialized) {
      const credentials = JSON.parse(localStorage.getItem("USER_AUTH"));
      console.log("authInitialized", credentials);
      if (credentials?.authenticated) {
        useUserStore.setState({
          authenticated: true,
          email: credentials.email,
        });
      }
      setAuthInitialized(true);
    }
  }, []);

  const router = createBrowserRouter([
    {
      element: <PrivateRoutes />,
      children: [
        {
          path: "/",
          element: <Projects />,
        },
        {
          path: "/project/:projectId",
          element: <Project />,
        },
        {
          path: "/schedule",
          element: <Schedule />,
        },
      ],
    },
    {
      path: "/login",
      element:     <App>
      <Login /></App>,
    },
    {
      path: "*",
      element: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight:'100vh',
            flexDirection:'column'
          }}
        >
                    <h1>404</h1>
          <h1>Page not found</h1>
        </div>
      ),
    },
  ]);

  return (
    <>
      {authInitialized ? <RouterProvider router={router} /> : <LoadingPage />}
    </>
  );
}

export default RouterView;
