import { Button, DatePicker, Input, InputNumber, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { api, apiSecure } from "../../components/api";

export const EditContactModal = ({
  editContactData,
  isModalOpen,
  setIsModalOpen,
  refreshData=()=>{}
}) => {
  const [name, setName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [sms, setSms] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (isModalOpen) {
      console.log("Modal opened", editContactData);
      setName(editContactData?.name);
      setJobTitle(editContactData?.details);
      setSms(editContactData?.phonenumber);
      setEmail(editContactData?.email);
    } else {
      setName('');
      setJobTitle('');
      setSms('');
      setEmail('');
    }
  }, [isModalOpen]);



  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const updateContact = () => {
    let req_config = {
      method: "POST",
      url: `/editProjectContact`,
      data: {
        contactId: editContactData?.id,
        name: name,
        details: jobTitle,
        number: sms,
        email: email
      },
    };
    console.log('req', req_config)

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        refreshData();
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  return (
    <>
      <Modal
        title="Muokkaa yhteystietoa"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Nimi:</p>

          <Input
            style={{ width: 150 }}
            status={!name}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Työnkuva:</p>

          <Input
            style={{ width: 150 }}
            status={!jobTitle}
            value={jobTitle}
            onChange={(e) => {
              setJobTitle(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Puhelinnumero:</p>

          <Input
            style={{ width: 150 }}
            status={!sms}
            value={sms}
            onChange={(e) => {
              setSms(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Sähköposti:</p>

          <Input
            style={{ width: 150 }}
            status={!email}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <br />
        <Button type="primary" onClick={updateContact}>
          Tallenna yhteystieto
        </Button>
      </Modal>
    </>
  );
};
