import { Button, DatePicker, Image, Input, InputNumber, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { api, apiSecure } from "../../components/api";

const _arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const FileModal = ({ fileData, isModalOpen, setIsModalOpen }) => {
  const [imgBase64, setImgBase64] = useState(null);
  const [url, setUrl] = useState(null);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const imageTypes = ["image/png", "image/jpeg"];

  const getFile = (fileId) => {
    console.log("getFile");

    let req_config = {
      method: "POST",
      url: `/getProjectFile`,
      data: {
        fileid: fileId,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        const bufferR = response.data.data.file.data;
        const filetype = response.data.data.filetype;

        if (imageTypes.includes(filetype)) {
          const base64String = _arrayBufferToBase64(bufferR);
          setImgBase64(base64String);
        }

        if (filetype == "application/pdf") {
          const url = window.URL.createObjectURL(
            new Blob([new Uint8Array(bufferR)], { type: "application/pdf" })
          );
          setUrl(url);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    if (isModalOpen) {
      getFile(fileData?.id);
    } else {
      setImgBase64(null);
      setUrl(null);
    }
  }, [isModalOpen]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        style={{ top: 20 }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {imgBase64 ? (
            /*         <img
              src={`data:image/png;base64,${imgBase64}`}
              alt=""
              style={{ maxWidth: "800px" }}
            /> */
            <Image height={'700px'} src={`data:image/png;base64,${imgBase64}`} />
          ) : (
            <></>
          )}

          {url ? (
            <iframe
              title="pdf"
              src={url}
              style={{ width: "100%", height: "950px" }}
            ></iframe>
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </>
  );
};
