import axios from "axios";
import config from "../config/config";
import { useUserStore } from "../state/state";


let isRefreshing = false;



export const api = axios.create({
    baseURL: config.api_url,
    timeout: 5 * 1000,
    headers: {
      "Content-Type": "application/json",
    },
  });




  export const apiSecure = axios.create({
    baseURL: config.api_url,
    timeout: 15 * 1000,
    headers: {
      "Content-Type": "application/json",
    },
  });

  //Token mukaan requesteihin
  apiSecure.interceptors.request.use(async (req) => {
    const credentials = JSON.parse(localStorage.getItem('USER_AUTH'))

    if (credentials) {
      const token = "Bearer " + credentials.accessToken;
      //console.log('APISECURE TOKEN:', token)
      req.headers.authorization = token;
    }

    return req;
  });

  //Token expired handling
  apiSecure.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      let nro = Math.random();
      const originalConfig = err.config;
      console.log('URL:', originalConfig.url,nro)

      //TODO
      if (isRefreshing) { 
        console.log('REFRESHING TOKEN.. RETURN',nro)
        return Promise.resolve();
      }

      if (err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            console.log('REFRESH LOGIC HERE',nro)
            isRefreshing = true;

            const credentials = JSON.parse(localStorage.getItem('USER_AUTH'))


            if (credentials) {

            console.log('CURRENT REFRESHTOKEN', credentials.refreshToken)


              let req_config = {
                method: "POST",
                url: `/refreshtoken `,
                data: {
                  refreshToken: credentials.refreshToken,
                },
              };

              console.log('START APICALL')
              await api(req_config)
              .then(async (response) => {
                console.log("RESPONSE FROM REFRESH", response.data);
                const { accessToken } = response.data;



               // console.log('ACCESS:', accessToken)
               // console.log('REFRESH:', refreshToken)

                await localStorage.setItem('USER_AUTH', JSON.stringify({
                  ...credentials,
                  accessToken: accessToken
                }));

                console.log('LÄPI')
                return
              })
              .catch((error) => {
                console.log("Error:", error);

                localStorage.removeItem("USER_AUTH");
                useUserStore.setState({
                  authenticated: undefined,
                  email: "",
                });
              });
            }


            console.log('TOKENS NOW', JSON.parse(localStorage.getItem('USER_AUTH')))
            //console.log('ASD2', originalConfig)



            /*
            const rs = await instance.post("/auth/refreshtoken", {
              refreshToken: TokenService.getLocalRefreshToken(),
            });
            const { accessToken } = rs.data; */
            //const accessToken = 
            //TokenService.updateLocalAccessToken(accessToken);


            isRefreshing = false;
            return apiSecure(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );


  /*
  //Jos Backendin token middleware palauttaa 401 == koitetaan noutaa uutta tokenia
  const refreshAuthLogic = async (failedRequest) => {
    const credentials = await SecureStore.getItemAsync("USER_AUTH");
  
    if (!credentials) {
      console.log("[refreshAuthLogic] Could not get credentials");
      return;
    }
  
    const jsonCredentials = JSON.parse(credentials);
  
    const data = {
      refreshToken: jsonCredentials.refreshToken,
    };
  
    const options = {
      method: "POST",
      url: config.api_url + "/refreshToken",
      data,
    };
  
    return axios(options)
      .then(async (RefreshResponse) => {
        const NewAccessToken = RefreshResponse.data.accessToken;
        const NewRefreshToken = RefreshResponse.data.refreshToken;
        console.log("NewAccessToken", NewAccessToken);
        failedRequest.response.config.headers.Authorization =
          "Bearer " + NewAccessToken;
  
        //Tallennetaan Uusi access token puhelimen dataan
        await SecureStore.setItemAsync(
          "USER_AUTH",
          JSON.stringify({
            ...jsonCredentials,
            accessToken: NewAccessToken,
            refreshToken: NewRefreshToken,
          })
        );
  
        return Promise.resolve();
      })
      .catch(async (e) => {
        console.log("[refreshAuthLogic] catch((e)", e);
        console.log('refreshAuthLogic error Loggin Out')
  
        await SecureStore.setItemAsync(
          "USER_AUTH",
          JSON.stringify({
            accessToken: null,
            refreshToken: null,
            authenticated: false,
          })
        );
      
        store.dispatch({
          type: "LOG_OUT",
        });
  
      });
  };
  
  createAuthRefreshInterceptor(apiSecure, refreshAuthLogic, {});
  
  */