import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { api, apiSecure } from "../../components/api";
import { useNavigate } from "react-router-dom";

export const AddSubProjectModal = ({
  projectId,
  isModalOpen,
  setIsModalOpen,
  refreshData = () => {},
}) => {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (isModalOpen) {
      console.log("Modal opened");
    } else {
      setName("");
    }
  }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addWork = () => {
    if (!projectId) return

    let req_config = {
      method: "POST",
      url: `/newProject`,
      data: {
        mainId: projectId,
        name: name,
      },
    };
    console.log("req", req_config);
    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        const {status} = response.data

        if (status) {
          refreshData();
          setIsModalOpen(false);
        }

      })
      .catch((error) => {
        console.log("Error:", error);
      }); 
  };

  return (
    <>
      <Modal
        title="Lisää aliprojekti"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <p style={{ marginRight: 10 }}>Nimi:</p>

          <Input
            style={{ width: 150 }}
            status={!name}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>


        <br />
        <Button type="primary" onClick={addWork}>
          Lisää aliprojekti
        </Button>
      </Modal>
    </>
  );
};
