import { Avatar, Button, Dropdown, Layout, Menu } from "antd";
import logo from "../images/pr-logo-big.png" // Tell webpack this JS file uses this image
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { useUserStore } from "../state/state";
import { UserOutlined } from "@ant-design/icons";

const navigationBtns = [
  { label: "Projektit", key: "/" },
  { label: "Aikataulu", key: "/schedule" },
];

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const email = useUserStore((state) => state.email);

  const handleMenuClick = ({ key }) => {
    if (key) {
      navigate(key);
    }
  };

  return (
    <Layout.Header
      style={{
        display: "flex",
      }}
    >
      <div style={{ marginRight: "20px", marginLeft: "-20px" }}>
        <img src={logo} alt="Logo" style={{ height: "20px" }} />;
      </div>

      <Menu
        style={{ width: "100%" }}
        mode="horizontal"
        defaultSelectedKeys={[location.pathname]}
        items={navigationBtns}
        onClick={handleMenuClick}
      />

      <div
        style={{
          marginLeft: "auto",
          textAlign: "start",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Dropdown
          menu={{
            items: [
              {
                label: email,
                key: "name",
                disabled: true,
              },
              /* 
                        {
                          label: "Lataa tiedosto",
                          key: "download",
                        }, */
              {
                label: "Kirjaudu ulos",
                key: "delete",
                danger: true,
                onClick: () => {
                  localStorage.removeItem("USER_AUTH");
                  useUserStore.setState({
                    authenticated: undefined,
                    email: "",
                  });
                },
              },
            ],
          }}
        >
          <Avatar
              style={{ backgroundColor: "#141414" }}
            icon={<UserOutlined />}
          />
        </Dropdown>
      </div>
    </Layout.Header>
  );
}
