import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { api, apiSecure } from "../../components/api";
import { useNavigate } from "react-router-dom";

export const AddProjectModal = ({
  isModalOpen,
  setIsModalOpen,
  refreshData = () => {},
}) => {
  const [name, setName] = useState("");
  const [haveSubprojects, setHaveSubprojects] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isModalOpen) {
      console.log("Modal opened");
    } else {
      setName("");
      setHaveSubprojects(false);
    }
  }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addWork = () => {
    let req_config = {
      method: "POST",
      url: `/newProject`,
      data: {
        name: name,
        haveSubprojects: haveSubprojects,
      },
    };
    console.log("req", req_config);
    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        const {status} = response.data

        if (status) {
          refreshData();
          setIsModalOpen(false);
          const nProjectId = response?.data?.data?.insertId
          if (nProjectId) {
            navigate("project/" + nProjectId)            
          }
        }

      })
      .catch((error) => {
        console.log("Error:", error);
      }); 
  };

  return (
    <>
      <Modal
        title="Lisää projekti"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <p style={{ marginRight: 10 }}>Nimi:</p>

          <Input
            style={{ width: 150 }}
            status={!name}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Checkbox
            checked={haveSubprojects}
            onChange={(e) => {
              console.log(`checked = ${e.target.checked}`);
              setHaveSubprojects(e.target.checked)
            }}
          >
            Sisältääkö aliprojekteja
          </Checkbox>
        </div>

        <br />
        <Button type="primary" onClick={addWork}>
          Lisää projekti
        </Button>
      </Modal>
    </>
  );
};
