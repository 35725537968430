import { Button, DatePicker, Input, InputNumber, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { api, apiSecure } from "../../components/api";

export const AddWorkModal = ({
  addWorkData,
  isModalOpen,
  setIsModalOpen,
  projects,
  groups,
  refreshData=()=>{}
}) => {
  const [textInput, setTextInput] = useState(null);

  const [workerValue, setWorkerValue] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [project, setProject] = useState(null);
  const [hours, setHours] = useState(null);

  useEffect(() => {
    if (isModalOpen) {
      console.log("Modal opened", addWorkData);
      setWorkerValue(addWorkData.worker);
    } else {
      setWorkerValue([]);
      setStartDate(null);
      setProject(null);
      setHours(null);
    }
  }, [isModalOpen]);

  useEffect(() => {
    console.log("workerValue", workerValue);
  }, [workerValue]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addWork = () => {
    console.log("addWork");
    console.log("workerValue", workerValue);
    console.log("startDate", startDate);
    console.log("project", project);
    console.log("hours", hours);

    if (workerValue.length <= 0) return;
    if (!startDate) return;
    if (!project) return;
    if (hours <= 0) return;
    console.log("läpi");

    let req_config = {
      method: "POST",
      url: `/newCalendarWork`,
      data: {
        workerValue: workerValue,
        startDate: startDate,
        project: project,
        hours: hours,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        refreshData();
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  return (
    <>
      <Modal
        title="Lisää työ"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'2em'
          }}
        >
          <p style={{ marginRight: 10 }}>Työntekijä(t):</p>
          <Select
            mode={"multiple"}
            style={{ width: 200 }}
            placeholder="Valitse työntekijä"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            status={workerValue.length <= 0 ? "error" : ""}
            value={workerValue}
            onChange={(newValue) => {
              setWorkerValue(newValue);
            }}
            options={groups?.map((itm) => {
              return { value: itm.id, label: itm.title };
            })}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Aloitus pvm:</p>

          <DatePicker
            value={startDate}
            format={"DD.MM.YYYY"}
            placeholder="Valitse pvm"
            onChange={(date, dateString) => {
              console.log(date, dateString);
              setStartDate(date);
            }}
            status={!startDate ? "error" : ""}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Projekti:</p>

          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Valitse projekti"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            status={!project ? "error" : ""}
            value={project}
            onChange={(newValue) => {
              setProject(newValue);
            }}
            options={
              projects?.map((itm) => {
                return { value: itm.id, label: itm.name };
              })
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Tunnit:</p>

          <InputNumber
            style={{ width: 150 }}
            addonAfter={"Tuntia"}
            step={8}
            status={!hours || hours <= 0 ? "error" : ""}
            value={hours}
            onChange={(value) => {
              console.log("changed", value);
              setHours(value);
            }}
          />

          <div>
            <p style={{ marginLeft: "10px", fontWeight: "bold" }}>
              {" "}
              {hours > 0 ? " " + hours / 8 + " työpäivää" : ""}{" "}
            </p>
          </div>
        </div>
        <br />
        <Button type="primary" onClick={addWork}>
          Lisää merkintä
        </Button>
      </Modal>
    </>
  );
};
