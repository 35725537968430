import { Button, DatePicker, Input, InputNumber, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { api, apiSecure } from "../../components/api";

export const AddWorksModal = ({
  projectId,
  isModalOpen,
  setIsModalOpen,
  refreshData=()=>{}
}) => {
  const [name, setName] = useState('');
  const [details, setDetails] = useState('');

  useEffect(() => {
    if (isModalOpen) {
      console.log("Modal opened");
    } else {
      setName('');
      setDetails('');
    }
  }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addWork = () => {
    let req_config = {
      method: "POST",
      url: `/newProjectWork`,
      data: {
        projectId: projectId,
        name: name,
        details: details,
      },
    };
    console.log('req', req_config)

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        refreshData();
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  return (
    <>
      <Modal
        title="Lisää työ"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Nimi:</p>

          <Input
            style={{ width: 150 }}
            status={!name}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:'10px'
          }}
        >
          <p style={{ marginRight: 10 }}>Lisätietoja:</p>

          <Input
            style={{ width: 150 }}
            status={!details}
            value={details}
            onChange={(e) => {
              setDetails(e.target.value);
            }}
          />
        </div>

      
        <br />
        <Button type="primary" onClick={addWork}>
          Lisää työ
        </Button>
      </Modal>
    </>
  );
};
