import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import RouterView from './pages/Router/Router';





/* const routes = [
  {
    path: "/",
    element: <PrivateRoutes />,
    children: [
      ... protected routes ...
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <PageNotFound />
  },
]; */



/* const RouteLogin = () => {
  const location = useLocation();
  const  authLogin  = true

  if (authLogin === undefined) {
    return null; // or loading indicator/spinner/etc
  }

  return !authLogin 
    ? <Login />
    : <Navigate to="/" replace state={{ from: location }} />;
} */





/* const router = createBrowserRouter([
  {
element: <Layout />,
children: [

  {
    path: "/",
    element: <Projects />,
  },
  {
    path: "/project/:projectId",
    element: <Project />,
  },
  {
    path: "/schedule",
    element: <Schedule />,
  },
  {
    path: "/login",
    element: <Login />,
  },

]
  }
]);
 */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <RouterView />
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
