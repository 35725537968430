import moment from "moment";
import { Tooltip as ReactTooltip } from 'react-tooltip'

//Välit viivoihin
export const formatLineStart = (date) => {
  let newD = moment(date).hours(1).minutes(0).seconds(0);
  return newD;
};
export const formatLineEnd = (date) => {
  let newD = moment(date).hours(23).minutes(0).seconds(0);
  return newD;
};


export const DrawTooltip = ({ id, type, data = {} }) => {
  console.log('DrawTooltip', id, type,data)
  let makeCount = null

/*   if (type == 'work') {
      let ValmistamattaKpl = data.kpl
      let workPercent = data.percent*100

      if (ValmistamattaKpl) {
        let count =  ((workPercent / 100) * ValmistamattaKpl).toFixed(0)   //  (KuormittamattaKpl/ValmistamattaKpl * 100).toFixed(0)
        //console.log('count', workPercent, ValmistamattaKpl, count)
        makeCount = count
      }
  }
 */


  return (
    <ReactTooltip id={id} aria-haspopup="true" className="tooltip-base" >

    <div className="tooltip-container">


      {type == "work" && (
        <>
          {true && (
            <p className="tooltip-header font-dm-medium">
             {data?.id +' ' +data?.name}
            </p>
          )}

          <div className="tooltip-row">

            <h1>asd</h1>
          </div>

{/*             {data.elementgroup && (
              <p className="tooltip-data font-dm-regular">
                {data.elementgroup}
              </p>
            )}

            {makeCount && (
              <p className="tooltip-data row font-dm-regular">
                {', '+makeCount+' kpl'}
              </p>
            )}

            {data.percent && (
              <p className="tooltip-data row font-dm-regular">
                {', '+(data.percent*100) +' %'}
              </p>
            )}

          {data.table && (
            <p className="tooltip-data font-dm-regular">
              {data.table}
            </p>
          )}

          {data.multiplier && (
            <p className="tooltip-data font-dm-regular">
              {"Henkilökohtainen kerroin " + data.multiplier.toFixed(1)}
            </p>
          )}

          {data.pvmReady && (
            <p className="tooltip-data font-dm-regular">
              {"Arvioitu valmistuminen " + data.pvmReady}
            </p>
          )} */}
        </>
      )}




      {type == "vacation" && (
        <>
          <p className="tooltip-header font-dm-medium">
            {data?.name}
          </p>

          <p className="tooltip-data font-dm-regular">
            {data?.startdate + " - " + data?.enddate}
          </p>

          <p className="tooltip-data font-dm-regular">
            {data.durationD && data.durationD + " päivää"}
            {data.durationD && data.durationH && ", "}
            {data.durationH && data.durationH + " h"}
          </p>
        </>
      )}

{type == "holiday" && (
        <>
          <p className="tooltip-header font-dm-medium">
            {data?.name}
          </p>

          <p className="tooltip-data font-dm-regular">
            {data?.date}
          </p>
        </>
      )}




</div>
    </ReactTooltip>
  );
};



export const drawLine = (
  array,
  group,
  title,
  date_start,
  date_end,
  type,
  data = {},
  formatDates = true
) => {
  console.log('drawLine array', date_start, date_end)
  console.log('drawLine', group,  title  + ' ('+(array.length + 1)+')' )

  array.push({
    id: group + "_" + array.length + 1,
    group: group,
    //title:title  + ' ('+(array.length + 1)+')'  || "Kohdetta ei ilmoitettu",
    title:title || "Kohdetta ei ilmoitettu",
    className: "item-type-" + type,
    type: type,
    start_time: formatDates ? formatLineStart(date_start) : date_start,
    end_time: formatDates ? formatLineEnd(date_end) : date_end,
    ...data,
  });
};

export const calculateUserNextJobTime = () => {};

export const calculateUserPrevJobTime = () => {};